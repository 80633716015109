<template>
  <b-card
    v-if="item"
    :title="item.title"
    :sub-title="isNew ? '新建' : `Updated at ${item.published}`"
  >
    <b-row>
      <!-- case -->
      <b-col cols="12">
        <b-form-group label="案例名称" label-for="h-name" label-cols-md="4">
          <b-form-input v-model="item.name" id="h-name" placeholder="案例名称" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="所属行业" label-for="h-name" label-cols-md="4">
          <b-form-select v-model="item.industry" id="h-industry" :options="industryOptions" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="涉案企业名称"
          label-for="h-enterprise"
          label-cols-md="4"
        >
          <b-form-input v-model="item.enterprise" id="h-enterpr" placeholder="涉案企业名称" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="事件时间" label-for="h-time" label-cols-md="4">
          <b-form-input v-model="item.time" id="h-time" placeholder="事件时间" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="事件描述"
          label-for="h-description"
          label-cols-md="4"
        >
          <b-form-textarea
            id="h-description"
            v-model="item.description"
            placeholder="事件描述"
            rows="5"
            max-rows="30"
          ></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="违反相关法规"
          label-for="h-violation"
          label-cols-md="4"
        >
          <b-form-textarea
            id="h-violation"
            v-model="item.violation"
            placeholder="违反相关法规"
            rows="5"
            max-rows="30"
          ></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="处罚依据" label-for="h-basis" label-cols-md="4">
          <b-form-textarea
            id="h-basis"
            v-model="item.basis"
            placeholder="处罚依据"
            rows="5"
            max-rows="30"
          ></b-form-textarea>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="执法单位" label-for="h-unit" label-cols-md="4">
          <b-form-input v-model="item.unit" id="h-unit" placeholder="执法单位" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group label="处罚类型" label-for="h-type" label-cols-md="4">
          <b-form-input v-model="item.type" id="h-type" placeholder="处罚类型" />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="处罚情况"
          label-for="h-punishment"
          label-cols-md="4"
        >
          <b-form-input v-model="item.punishment" id="h-punishment" placeholder="处罚情况" />
        </b-form-group>
      </b-col>

      <b-col lg="12" class="mt-1 d-flex justify-content-end">
        <b-button variant="primary" class="mr-1">保存</b-button>
        <b-button @click="onCancel">取消</b-button>
        <b-button variant="outline-danger" class="ml-2">删除</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BRow,
  BCol,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BButton,
  BFormFile,
  BIcon,
  BIconAlarm,
} from "bootstrap-vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import pdf from "vue-pdf";

import mammoth from "mammoth";

export default {
  name: "LibraryCaseEdit",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardTitle,
    BRow,
    BCol,
    BFormCheckbox,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormFile,
    BIcon,
    BIconAlarm,
    quillEditor,
    mammoth,
    pdf,
  },

  data() {
    return {
      id: this.$route.params.id,
      item: null,

      industryOptions: [
        { value: "", text: "应用行业" },
        "互联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],

      lawOptions: ["GDPR", "CCPA", "PIPL"],
    };
  },

  computed: {
    isNew() {
      return this.id === "new";
    },
  },

  mounted() {},

  beforeMount() {
    if (this.id == "new") {
      const item = {
        id: 0,
        code: null,
        title: null,
        overview: null,
        content: "",
        apply: [],
        type: null,
        attachments: null,
        status: "active",
      };
      this.item = item;
    } else {
      this.$http.get("/api/lib-case/" + this.id).then((res) => {
        console.log(res.data);
        this.item = res.data.item;
      });
    }
  },

  methods: {
    onCancel() {
      this.$router.push({ name: "library-case" });
    },
  },
};
</script>

<style >
.ql-editor {
  min-height: 320px;
}
</style>