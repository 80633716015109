<template>
  <b-row v-if="item">
  <b-col cols="12">
      <span class="font-weight-bolder">{{ item.name }}</span>
      <b-badge variant="primary" class="ml-1">{{ item.industry }} </b-badge>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">涉案时间: </span><span style="">{{ item.time }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">涉案企业名称: </span><span style="">{{ item.enterprise }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">执法单位: </span><span style="">{{ item.unit }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">处罚类型: </span><span style="">{{ item.type }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">处罚情况: </span><span style="">{{ item.punishment }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">事件描述: </span><span style="">{{ item.description }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">违反相关法规: </span><span style="">{{ item.violation }}</span>
    </b-col>

    <b-col cols="12" class="mt-1">
      <span class="font-weight-bolder">处罚依据: </span><span style="">{{ item.basis }}</span>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BBadge, BCardText } from "bootstrap-vue";

export default {
  name: "LibCaseView",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  components: {
    BRow,
    BCol,
    BBadge,
    BCardText,
  },

  data() {
    return {
      //
    };
  },
};
</script>
